//import { DisplayLabel } from './components/DisplayLabel';

import 'bootstrap';
// import 'dark-mode-switch';
//import '/css/test.scss';

//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';

//import './dark-mode-switch.js';

//https://swiperjs.com/get-started
//import Swiper from 'swiper';
  // import Swiper styles
  //import 'swiper/css';

  //const swiper = new Swiper(...);

//import Masonry from 'masonry-layout';


//npm install flickity-imagesloadedimport PhotoSwipeLightbox from 'photoswipe/lightbox';

//https://www.lightgalleryjs.com/
// import lightGallery from 'lightgallery';

// // Plugins
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

//import { tns } from "./node_modules/tiny-slider/src/tiny-slider"


//var msnry = new Masonry( '.grid', {});

// let Main = {
//   init: async function () {

//     // initialize demo javascript component - async/await invokes some 
//     //  level of babel transformation
//     const displayLabel = new DisplayLabel();
//     await displayLabel.init();

//   }
// };

var Flickity = require('flickity');
require('flickity-imagesloaded');
require('flickity-fullscreen');

//Main.init();


document.addEventListener("DOMContentLoaded", function() {
  
  
  var body_ = document.querySelector("body");
 
  var st = setTimeout(function(){
    // highlightedItems.forEach((userItem) => {
    //   userItem.classList.remove("hide-car");
    // });
    body_.classList.add('page-loaded');

  },50); 

 
  //alert('content - loaded [debug]');

});


document.addEventListener('mouseover', function (event) {
  //console.log(event.target);
  // If the clicked element doesn't have the right selector, bail
  var closest = event.target.closest('.card');
  var closest_row = event.target.closest('.row');
  if (!closest) return;
  console.log(event);
  closest.classList.add('active');
  closest_row.classList.add('active-row');
  
  //alert('doet ie wel!');
  // Don't follow the link
  //event.preventDefault();

  // Log the clicked element in the console
}, false);

document.addEventListener('mouseout', function (event) {
  //console.log(event.target);
  // If the clicked element doesn't have the right selector, bail
  var closest = event.target.closest('.card');
  var closest_row = event.target.closest('.row');
  if (!closest) return;
  console.log(event);
  closest.classList.remove('active');
  closest_row.classList.remove('active-row');

  //alert('doet ie wel!');
  // Don't follow the link
  //event.preventDefault();

  // Log the clicked element in the console


}, false);


console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
}else{
  console.log('Looks like we are in production mode');
}
 

